import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layouts/pl"
import Fade from 'react-reveal/Fade';

const PolitykaPrywatnosci = (props) => {
    const data = useStaticQuery(graphql` 
        query {
            file(relativePath: { eq: "images/photos/27-01-dziwnowek.jpg" }) {
                childImageSharp {
                    fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                    }
                }   
            }
        }
    `);


    return (
        <Layout location={props.location}>
            <Fade up>
                <section>
                    <Img fluid={data.file.childImageSharp.fluid} alt="Dziwnowek apartamenty Amber" />
                    <h1 className="title">Polityka Prywatnosci</h1>
                    <div className="content-container">
                        <h2>Informacja dotycząca zbierania danych osobowych</h2>
                        <p>Poniżej informujemy o zbieraniu danych osobowych podczas korzystania z naszej strony internetowej. Dane osobowe to wszystkie dane, które można odnieść do konkretnej osoby, np. nazwisko, adres, adres e-mail, zachowanie użytkownika.</p>
                        <p>W przypadku nawiązania kontaktu z nami przez e-mail podane przez Państwa dane (Państwa adres e-mail, ew. nazwisko, stanowisko i numer telefonu) są przez nas zapisywane, aby możliwe było udzielenie informacji na Państwa zapytanie. Uzyskane w ten sposób dane są przez nas usuwane, kiedy ich przechowywanie nie jest już konieczne, lub ograniczamy ich przetwarzanie, jeśli istnieje ustawowy obowiązek przechowywania.</p>

                        <h2>Przysługujące Państwu prawa</h2>
                        <p>W odniesieniu do Państwa danych osobowych przysługują Państwu następujące prawa wobec nas:</p>
                        <ul>
                            <li>prawo do informacji,</li>
                            <li>prawo do poprawiania lub usuwania,</li>
                            <li>prawo do ograniczenia przetwarzania,</li>
                            <li>prawo do sprzeciwu wobec przetwarzania danych osobowych,</li>
                            <li>prawo do przenoszenia danych</li>
                        </ul>

                        <h2>Zbieranie danych osobowych podczas wizyty na naszej stronie internetowej</h2>
                        <p>Kiedy strona internetowa jest wykorzystywana jedynie w celu uzyskania informacji, to znaczy jeśli nie rejestrują się Państwo lub nie przekazują nam Państwo informacji w inny sposób, zbieramy tylko te dane osobowe, które są przekazywane na nasz serwer przez Państwa przeglądarkę. Jeśli chcą Państwo przeglądać naszą stronę internetową, zbieramy następujące dane, które są nam potrzebne ze względów technicznych, aby wyświetlać naszą stronę internetową oraz zagwarantować jej stabilność i bezpieczeństwo (podstawę prawną stanowi art. 6 ust. 1 z. 1 lit. f RODO):</p>
                        <ul>
                            <li>adres IP</li>
                            <li>data i godzina zapytania</li>
                            <li>różnica strefy czasowej w stosunku do Greenwich Mean Time (GMT)</li>
                            <li>treść żądania (konkretna strona)</li>
                            <li>status dostępu/ kod odpowiedzi HTTP</li>
                            <li>przesyłana ilość danych</li>
                            <li>przeglądarka</li>
                            <li>system operacyjny i jego interfejs</li>
                            <li>język i wersja oprogramowania przeglądarki.</li>
                        </ul>

                        <p>Dodatkowo, oprócz wyżej wymienionych danych, podczas korzystania z naszej strony internetowej na Państwa komputerze zapisywane są pliki cookies. Pliki cookies to niewielkie pliki tekstowe, zapisywane w pamięci Państwa komputera i przyporządkowane do wykorzystywanej przez Państwa przeglądarki. Dzięki nim do organu, który wysyła plik cookie (w tym przypadku do nas), docierają określone informacje. Pliki cookies nie mogą uruchamiać żadnych programów ani przenosić wirusów na Państwa komputer. Dzięki nim oferta internetowa jest bardziej przyjazna dla użytkownika i efektywna.</p>


                        <h2>Google Analytics</h2>
                        <p>Ta  strona internetowa korzysta z Google Analytics, usługi analizowania usług internetowych, oferowanej przez Google Inc. („Google”). Google Analytics wykorzystuje tzw. „Cookies”, pliki tekstowe, które są zapisywane na Państwa komputerze i umożliwiają analizę korzystania przez Państwa ze strony internetowej. Uzyskane przez plik cookie informacje dotyczące sposobu korzystania przez Państwa z tej strony internetowej są zwykle przekazywane na serwer Google w USA i tam zapisywane. W przypadku aktywowania anonimizacji IP na tej stronie internetowej w obrębie państw członkowskich Unii Europejskiej lub w innych krajach, które są stronami Porozumienia o Europejskim Obszarze Gospodarczym, Państwa adres IP zostanie jednak najpierw skrócony przez Google. Tylko w wyjątkowych przypadkach pełny adres IP jest przekazywany na serwer Google w USA i tam skracany. Na zlecenie właściciela tej strony internetowej firma Google wykorzystuje te informacje do analizy Państwa sposobu korzystania ze strony internetowej, sporządzania raportów dotyczących aktywności na stronie internetowej oraz realizacji dalszych usług związanych z wykorzystaniem strony internetowej i Internetu na rzecz właściciela strony internetowej.</p>
                        <p>Adres IP przekazany przez Państwa przeglądarkę w ramach usługi Google Analytics nie jest zestawiany przez Google z innymi danymi.</p>
                        <p>Można zapobiec zapisywaniu plików cookies przez odpowiednie ustawienia w oprogramowaniu przeglądarki. Zwracamy jednak uwagę, że w takim przypadku nie będą Państwo mogli w pełni korzystać ze wszystkich funkcji tej strony internetowej. Ponadto mogą Państwo zapobiec pobieraniu danych uzyskanych przez plik cookie i odnoszących się do Państwa sposobu korzystania ze strony internetowej (łącznie z adresem IP) przez Google, a także przetwarzaniu tych danych przez Google, jeśli pobiorą Państwo i zainstalują wtyczkę do przeglądarki, dostępną pod następującym linkiem: <a href="tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">tools.google.com/dlpage/gaoptout</a>.</p>
                        <p>Korzystamy z Google Analytics w celu analizowania korzystania z naszej strony internetowej oraz jej regularnego usprawniania. Dzięki uzyskanym statystykom możemy poprawiać naszą ofertę i uczynić ją bardziej interesującą dla Państwa jako użytkowników. W odniesieniu do wyjątkowych przypadków, w których dane osobowe przekazywane są do USA, Google podporządkowuje się porozumieniu EU-US Privacy Shield, www.privacyshield.gov/EU-US-Framework. Podstawę prawną dla korzystania z Google Analytics stanowi art. 6 ust. 1 zd. 1 lit. f) RODO.</p>

                        <h2>Odnośniki do innych stron</h2>
                        <p>Serwis zawiera odnośniki do innych stron www. Nie możemy ponosić odpowiedzialności za zasady obowiązujące na tych stronach. Sugerujemy, aby po przejściu na inne strony, zapoznać się z polityką prywatności tam obowiązującą. Niniejsza polityka plików cookies dotyczy jedynie naszego serwisu.</p>
                    </div>
                </section>
            </Fade>
        </Layout>
    )
}


export default PolitykaPrywatnosci;
